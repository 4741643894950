'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';
import { env } from '~/env.mjs';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export default function ClientScript(props: { user?: { id: string; email: string; name: string; phone: string } }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const pageview = (url: string) => {
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'pageview',
        page: url,
        ...(!!props?.user && {
          userId: props.user.id,
          userEmail: props.user.email,
          userName: props.user.name,
          isLoggedIn: true
        })
      });
      if (!!props?.user) {
        window.dataLayer.push({
          event: 'identify_user',
          userId: props.user.id,
          userEmail: props.user.email,
          userName: props.user.name,
          userPhone: props.user.phone,
          isLoggedIn: true
        });
      }
    } else {
      console.log({
        event: 'pageview',
        page: url
      });
    }
  };

  useEffect(() => {
    if (pathname) {
      pageview(pathname);
    }
  }, [pathname, searchParams]);

  return (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${env.NEXT_PUBLIC_GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${env.NEXT_PUBLIC_GTM_ID}');
  `
        }}
      />
    </>
  );
}
