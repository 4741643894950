import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query-devtools@4.32.6_@tanstack+react-query@4.32.6_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query-devtools/build/lib/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.13.0_next@14.2.1_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-sans\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/nextjs-toploader@1.6.4_next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/node_modules/.pnpm/react-toastify@10.0.4_react-dom@18.2.0_react@18.2.0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/posthog/posthog-pageview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-toastify@10.0.4_react-dom@18.2.0_react@18.2.0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/src/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/utils/google-tag-manager/client-script.tsx");
